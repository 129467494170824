<template>
  <div>
    <d-body-top-bar
        :title="$t('data.menu.news.menu')"
        :full-body="false"
    />
    <list/>
  </div>
</template>
<script>
export default {
  components: {
    List: () => import('./List')
  },
}
</script>
<style scoped>
</style>
